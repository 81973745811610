import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "@/views/LoginView.vue";
import RecuperarView from "@/views/RecuperarView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "default",
    component: LoginView,
  },
  {
    path: "/recuperar",
    name: "recuperar",
    component: RecuperarView,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
