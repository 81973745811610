
import { Component, Vue } from "vue-property-decorator";
import logo from "@/assets/logo.svg";

@Component({
  components: {
    logo,
  },
})
export default class App extends Vue {
  public loading = true;
  public stopLoading = false;
  public transition = "slide-left";

  public created() {
    this.$router.afterEach((to, from) => {
      console.log("to", to);
      console.log("from", from);

      if (to.name === "default") {
        this.transition = "slide-right";
      } else if (from.name === "recuperar") {
        this.transition = "slide-right";
      } else {
        this.transition = "slide-left";
      }
    });

    setTimeout(() => {
      this.stopLoading = true;
    }, 1000);
  }

  public goBack() {
    return history.back();
  }
}
